import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../components/Layout"
import UserContainer from "../containers/UserContainer"
import SignContainer from "../containers/SignContainer"
import ModalPopup from "../popups/ModalPopup"
import { setSignUpPopup } from "../store/modules/popup"
import { localStorgeGet } from "../utils/util"

//회원가입 이메일 인증페이지
const authSignEmails = props => {
  const { putSignupDatas, setAuthEmailSeq } = props
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [text, setText] = useState("")
  const signUpPopup = useSelector(state => state.popup.signUpPopup)
  const [sign, setSign] = useState(false)

  const checkFn = async () => {
    if (props.location.search == "" || props.location.search == undefined) return false
    let temp = props.location.search.substring(1)
    let arr = temp.split("/")
    let result = await putSignupDatas({ customerAppSignDataSeq: arr[0], authString: arr[1] })
    if (result) {
      setAuthEmailSeq({ authEmailSeq: result.authEmailSeq, customerAppSignDataSeq: arr[0] })
      setText("인증에 성공하였습니다.")
      setOpenModal(true)
      setSign(true)

      dispatch(setSignUpPopup(true))
    } else {
      setText("인증에 실패하였습니다.")
      setOpenModal(true)
      navigate("/exploring")
    }
  }

  useEffect(() => {
    checkFn()
  }, [])

  useEffect(() => {
    if (!signUpPopup && sign) {
      let studySeq = localStorgeGet("inviteStudy")
      if (studySeq) {
        localStorage.removeItem("inviteStudy")
        navigate("/exploring" + studySeq)
      } else {
        navigate("/exploring")
      }
    }
  }, [signUpPopup])

  return (
    <Layout>
      {" "}
      <ModalPopup open={openModal} onClose={() => setOpenModal(false)} contents={text} />
    </Layout>
  )
}

export default SignContainer(authSignEmails)
